import React from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IProduct from "../../../Interfaces/IProduct";
import IInputField from "../../../Interfaces/IInputField";
import { SectionTitle } from "../../Others/SectionTitle";
import { inject, observer } from "mobx-react";
import { listsStore } from "../../../App";
import ListsStore from "../../Stores/ListsStore";
import StringHelper from "../../../Helpers/StringHelper";
import MultiSelectBox from "../../UI/Input/MultiSelectBox";
import { EquipmentCategory } from "../../../Enums/EquipmentCategory";
import IOptionData from "../../../Interfaces/IOptionData";

interface IProps {
    product: IProduct,
    onChange(key: string, value: any): void,
    requiredFields: string[],
    listsStore?: ListsStore,
}

export const CarsEquipmentFormCard = inject('listsStore')(observer(React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const {
        product,
        requiredFields
    } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
           
        ]
    }

    const safetySystems = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.SafetySystems);
    const assistanceSystems = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.AssistanceSystems);
    const seats = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.Seats);
    const onboardSystemsAndConnectivity = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.OnboardSystemsAndConnectivity);
    const vehicleSecurity = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.VehicleSecurity);
    const lightingTechnology = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.LightingTechnology);
    const driveAndChassis = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.DriveAndChassis);
    const exterior = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.Exterior);
    const interiorEquipmentAndComfort = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.InteriorEquipmentAndComfort);
    const other = listsStore.getVehicleEquipmentsOptions(EquipmentCategory.Other);

    const onChange = (ids: string[], key: string, options: IOptionData[]) => {
        const toRemoveIds = options.map(x => x.value);
        var x = (product.equipments || []).filter(s => !toRemoveIds.includes(s));
        const toAdd = ids.map(s => parseInt(s));

        toAdd.forEach(v => {
            x.push(v);
        });
        props.onChange(key, x);
    }

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="Výbava" className="my-0" />
            </div>
            <div className="card-body">
                <div className="pb-2">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Bezpečnostní systémy"
                                onChange={(ids, key) => onChange(ids, key, safetySystems)}
                                options={safetySystems}
                                selected={StringHelper.convertToOptionData(product.equipments, safetySystems)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Asistenční systémy"
                                onChange={(ids, key) => onChange(ids, key, assistanceSystems)}
                                options={assistanceSystems}
                                selected={StringHelper.convertToOptionData(product.equipments, assistanceSystems)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                      
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Palubní systémy a konektivita"
                                onChange={(ids, key) => onChange(ids, key, onboardSystemsAndConnectivity)}
                                options={onboardSystemsAndConnectivity}
                                selected={StringHelper.convertToOptionData(product.equipments, onboardSystemsAndConnectivity)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Sedadla"
                                onChange={(ids, key) => onChange(ids, key, seats)}
                                options={seats}
                                selected={StringHelper.convertToOptionData(product.equipments, seats)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Vnitřní výbava a komfort"
                                onChange={(ids, key) => onChange(ids, key, interiorEquipmentAndComfort)}
                                options={interiorEquipmentAndComfort}
                                selected={StringHelper.convertToOptionData(product.equipments, interiorEquipmentAndComfort)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Zabezpečení vozidla"
                                onChange={(ids, key) => onChange(ids, key, vehicleSecurity)}
                                options={vehicleSecurity}
                                selected={StringHelper.convertToOptionData(product.equipments, vehicleSecurity)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Světla"
                                onChange={(ids, key) => onChange(ids, key, lightingTechnology)}
                                options={lightingTechnology}
                                selected={StringHelper.convertToOptionData(product.equipments, lightingTechnology)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Pohon a podvozek"
                                onChange={(ids, key) => onChange(ids, key, driveAndChassis)}
                                options={driveAndChassis}
                                selected={StringHelper.convertToOptionData(product.equipments, driveAndChassis)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Vnější výbava"
                                onChange={(ids, key) => onChange(ids, key, exterior)}
                                options={exterior}
                                selected={StringHelper.convertToOptionData(product.equipments, exterior)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
           
                        <div className="col-12 col-lg-6">
                            <MultiSelectBox
                                label="Ostatní"
                                onChange={(ids, key) => onChange(ids, key, other)}
                                options={other}
                                selected={StringHelper.convertToOptionData(product.equipments, other)}
                                uid="equipments"
                                isClearable={false}
                                keepMenuOpenOnSelect
                            />
                        </div>
                    </div>
                    <small>Některé inzertní portály nepodporují zadávání výbavy, proto je potřeba ji uvést do popisku.</small>
                </div>
            </div>
        </div>
    </>
})))
import { IAccountTransaction } from "../../Interfaces/IAccountTransaction";
import { TransactionTableRow } from "./TransactionTableRow";

interface IProps {
    transactions: IAccountTransaction[],
    className?: string
}

export const TransactionsTable = (props: IProps) => {

    const transactions = props.transactions;

    return (
        <>
            <div className={`table-responsive ${props.className}`}>
                <table className="table table-stripped">
                    <thead>
                        <tr>
                            <th>Datum</th>

                            <th>Typ</th>

                            <th>Popis</th>

                            <th>Částka</th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {transactions.map(transaction => {
                            return <TransactionTableRow key={transaction.id} transaction={transaction} />
                        })}
                    </tbody>
                </table>
            </div>
        </>)
}
import React, { useRef } from "react";
import ProductContentEditor from "./../ContentEditor/ProductContentEditor"
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IProduct from "../../../Interfaces/IProduct";
import IUser from "../../../Interfaces/IUser";
import IGlobalSettings from "../../../Interfaces/IGlobalSettings";
import IProductCollection from "../../../Interfaces/IProductCollection";
import InputUrl from "../../UI/Input/InputUrl";
import IOptionData from "../../../Interfaces/IOptionData";
import IInputField from "../../../Interfaces/IInputField";
import { SectionTitle } from "../../Others/SectionTitle";

interface IProps {
    product: IProduct,
    user: IUser,
    settings: IGlobalSettings,
    collection: IProductCollection,
    onChange(key: string, value: any): void,
    onBatchUpdate(values: { [key: string]: any }): void,
    subcategories: IOptionData[],
    requiredFields: string[],
    foldAsComplete?: boolean
}

export const BasicInfoFormCard = React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const contentEditorRef = useRef<ProductContentEditor>(null);
  
    const {
        product,
        user,
        settings,
        collection,
        onChange,
        requiredFields,
        foldAsComplete
    } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
        isContentValidAsync,
    }));

    const isContentValidAsync = (): Promise<boolean> => {
       return contentEditorRef.current.isContentValidAsync();
    }
    
    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
            externalUrlRef,
        ]
    }

    const externalUrlRef = useRef<InputUrl>(null);

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle className="my-0">
                    <>
                        {foldAsComplete && <i className="fas fa-check mr-1"></i>} Obsah inzerátu
                    </>
                </SectionTitle>
            </div>

            {!foldAsComplete &&
                <div className="card-body">

                    {/* TODO VALIDATION */}
                    <ProductContentEditor
                        ref={contentEditorRef}
                        product={product}
                        onChange={(value, key) => onChange(key, value)}
                        globalSettings={settings}
                        collection={collection}
                        user={user}
                        onBatchUpdate={props.onBatchUpdate}
                    />
                </div>
            }
        </div>
    </>
})
import { inject, observer } from "mobx-react";
import { OnlineServer } from "../../../../Enums/OnlineServer";
import AutobazarEuApiService from "../../../../Services/AutobazarEuApiService";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import { SectionTitle } from "../../../Others/SectionTitle";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import CheckBox from "../../../UI/Input/CheckBox";
import InputText from "../../../UI/Input/InputText";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import { Link } from "react-router-dom";
import IUser from "../../../../Interfaces/IUser";

interface IProps {
    currentUserStore?: CurrentUserStore
}

const autobazarEuApiService = new AutobazarEuApiService();

export const AutobazarEuExportPage = inject('currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore.user;


    const isEnabledServer = user.uploaderServers.includes(OnlineServer.AutobazarEu);

    const handleAllow = (user: IUser) => {
        if (user.uploaderServers.includes(OnlineServer.AutobazarEu)) {
            autobazarEuApiService.disallowExport();
        }
        else {
            autobazarEuApiService.allowExport();
        }
    }

    return (
        <CustomDocumentTitle title="Autobazar.eu - EXPORT">
            <PageTitleBox title="Autobazar.eu - EXPORT" />
            <div className="card mb-0">
                <div className="card-body">
                    <SectionTitle title="Podporované kategorie" />

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Kategorie</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Osobné autá / úžitkové vozidlá do 3,5t</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="alert alert-warning">
                        <b>Máte zájem o jinou kategorii? Kontaktujte <Link to="/kontakt">podporu</Link></b>
                    </div>

                    <hr />

                    <p>Export na inzertní server <a target="_blank" href="https://www.autobazar.eu/">Autobazar.eu</a> je realizován prostřednictvím datového feedu.</p>

                    <SectionTitle title="Jak propojit s Autobazar.eu" className="mt-4" />
                    <p className="text-danger">Dodržte přesné pořadí kroků. V momentě propojení, musíte mít již doplněné potřebné údaje a zapnutý Autobazar.eu, aby se inzeráty již vyskytovaly ve feedu.</p>
                    <ol>
                        <li>Povolte export na této stránce</li>
                        <li>Povolte export u jednotlivých inzerátů v Inzerobotovi v sekci "Marketingové kanály"</li>
                        <li>Doplňte všechny vyžadované pole. Uložte změny každého inzerátu po úpravě.</li>
                        <li>Kontaktujte Autobazar.eu a předejte jim níže uvedenou URL datového feedu (url se objeví až jakmile je export povolen)</li>
                        <li>Autobazar.eu si bude automaticky stahovat inzerci</li>
                    </ol>

                    <CheckBox
                        checked={isEnabledServer}
                        label="Povolit export"
                        onChange={() => handleAllow(user)}
                    />

                    {isEnabledServer &&
                        <div className="mt-3">
                            <InputText
                                label="URL feedu JSON"
                                value={`https://core.inzerobot.cz/api/autobazareu/export/v2?userId=${user.id}`}
                                readonly
                            />
                            <p>Pokud export nepoužíváte, prosím opět jej vypněte. Zároveň to slouží pro nás k informaci, že tuto funkci využíváte. Děkujeme</p>
                        </div>}
                </div>
            </div>
        </CustomDocumentTitle>)
}))
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class AccountTransactionsApiService extends BaseApiService {

    public getAccountTransactions(takeCount: number, skipCount: number): Promise<IResponseData> {
        return this.getRequest('accounttransactions', { takeCount: takeCount.toString(), skipCount: skipCount.toString() })
            .then(response => {
                return response.data;
            });
    }

    public getAccountTransaction(id: string): Promise<IResponseData> {
        return this.getRequest(`accounttransactions/${id}`, {})
            .then(response => {
                return response.data;
            });
    }
}
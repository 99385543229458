import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class AutobazarEuApiService extends BaseApiService {

    readonly baseUrl: string = "autobazareu";
    
    public allowExport(): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/export/allow`, { }, {})
            .then(response => {
                return response.data;
            })
    }

    public disallowExport(): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/export/disallow`, {}, {})
            .then(response => {
                return response.data;
            })
    }
}
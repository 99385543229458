import { uniq } from "lodash";
import { OnlineServerHelper } from "../components/Utils/OnlineServerHelper";
import { AutoImporterSourceType } from "../Enums/AutoImporterSourceType";
import { Currency } from "../Enums/Currency";
import { OnlineServer } from "../Enums/OnlineServer";
import { ProductServerErrorType } from "../Enums/ProductServerErrorType";
import { RealEstateType } from "../Enums/RealEstateType";
import { VehicleType } from "../Enums/VehicleType";
import IOptionData from "../Interfaces/IOptionData";
import IProduct from "../Interfaces/IProduct";
import IProductCollection from "../Interfaces/IProductCollection";
import IUser from "../Interfaces/IUser";
import { autoImporterStore } from './../App';
import { ICurrencyConversions } from "../Interfaces/ICurrencyConversions";
import Product from "../Models/Product";
import CurrencyHelper from "./CurrencyHelper";
import Swal from "sweetalert2";

export class ProductHelper {

    public static getMaxDescriptionChars(product: IProduct, collection: IProductCollection): number {
        let maxChars = 3000;

        if (product.sbazar) {
            if (collection.sbazarCurrentDateDescriptionPrefix) {
                maxChars = 1000 - 13;
            }
            else {
                maxChars = 1000;
            }
        }

        if (product.bazosCZ || product.bazosSK) {
            if (collection.bazosCzCurrentDateDescriptionPrefix) {
                if (maxChars > (3000 - 13)) { maxChars = 3000 - 13; }
            }
            else {
                if (maxChars > 3000) { maxChars = 3000; }
            }
        }
        return maxChars;
    }

    public static getMaxUniversalNameCharsCount(product: IProduct): number {
        return product.sbazar ? 50 : 60;
    }

    public static getMaxNameCharsCount(): number {
        return 60;
    }

    public static getOnlineServers(product: IProduct, collection: IProductCollection, user: IUser): OnlineServer[] {

        let servers: OnlineServer[] = [];

        if (collection) {
            if (collection.bazosCZ)
                servers.push(OnlineServer.BazosCz);

            if (collection.bazosSK)
                servers.push(OnlineServer.BazosSk);

            if (collection.sbazar)
                servers.push(OnlineServer.Sbazar);
        }

        if (user.uploaderServers.includes(OnlineServer.Sbazar) && collection.unlimited) {
            servers.push(OnlineServer.Sbazar);
        }

        if ((product.autoImporterId || '').length > 0) {
            const importer = autoImporterStore.findById(product.autoImporterId);

            if (importer) {
                if (importer.sourceType === AutoImporterSourceType.Sauto) {
                    servers.push(OnlineServer.Sauto);
                }
                else if (importer.sourceType === AutoImporterSourceType.Tipcars) {
                    //   servers.push(OnlineServer.Tipcars);
                }
            }
        }

        servers.push(OnlineServer.Website);

        servers.push(OnlineServer.FacebookShop);

        if (user.uploaderServers.includes(OnlineServer.FacebookMarketplace)) {
            servers.push(OnlineServer.FacebookMarketplace);
        }

        if (user.uploaderServers.includes(OnlineServer.Sauto)) {
            servers.push(OnlineServer.Sauto);
        }
      
        if (product.vehicleType === VehicleType.QuadBike
            || product.vehicleType === VehicleType.Motorcycle) {
            servers.push(OnlineServer.TipMoto);
        }

        if (OnlineServerHelper.isServerSupported(product, OnlineServer.AutobazarEu)) {
            servers.push(OnlineServer.AutobazarEu);
        }
        if (OnlineServerHelper.isServerSupported(product, OnlineServer.GoogleMerchant)) {
            servers.push(OnlineServer.GoogleMerchant);
        }
        if (user.uploaderServers.includes(OnlineServer.Kleinanzeigen)) {
            servers.push(OnlineServer.Kleinanzeigen);
        }
        return uniq(servers);
    }

    public static isBrandRequired(product: IProduct): boolean {
        const unsupported = [VehicleType.Car, VehicleType.Motorcycle, VehicleType.Commercial, VehicleType.Truck, VehicleType.QuadBike]
        return !unsupported.includes(product.vehicleType);
    }

    public static getFatalErrorTypes(): ProductServerErrorType[] {
        return [
            ProductServerErrorType.FatalErrorDelete,
            ProductServerErrorType.FatalErrorEdit,
            ProductServerErrorType.FatalErrorUpload,
            ProductServerErrorType.FatalErrorCheck,
        ];
    }

    public static getRealEstateTypeOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: this.getRealEstateTypeLabel(RealEstateType.Sale), value: RealEstateType.Sale },
            { label: this.getRealEstateTypeLabel(RealEstateType.Lease), value: RealEstateType.Lease },
        ];
        return options;
    }

    public static getRealEstateTypeLabel(type: RealEstateType): string {
        switch (type) {
            case RealEstateType.Lease:
                return "Pronájem";

            case RealEstateType.Sale:
                return "Prodej";

            default:
                return "";
        }
    }

    public static getPriceByCurrency(product: IProduct, currency: Currency) {
        switch (currency) {

            case Currency.Czk:
                return product.priceCZK;

            case Currency.Eur:
                return product.priceEUR;

            case Currency.PLN:
                return product.pricePLN;

            default:
        }
    }

    public static getPriceWithoutVat(priceWithVat: number, vatRate: number): number {
        return priceWithVat / (1 + (vatRate / 100));
    }

    public static roundPriceWithTolerance(price: number, tolerancePercentage: number): number {
        let number: number = Math.floor(price);
        const tolerance: number = (number / 100) * tolerancePercentage;

        if (number >= 100000) {
            // Round to thousands
            let rounded: number = Math.round((number + 500) / 1000) * 1000;
            let diff: number = Math.abs(rounded - number);

            if (diff > tolerance) {
                // Round to hundreds
                rounded = Math.round((number + 50) / 100) * 100;
                diff = Math.abs(rounded - number);

                if (diff > tolerance) {
                    return number;
                }
            }
            return rounded;
        } else if (number >= 100) {
            // Round to hundreds
            let rounded: number = Math.round((number + 50) / 100) * 100;
            let diff: number = Math.abs(rounded - number);

            if (diff > tolerance) {
                return number;
            } else {
                return rounded;
            }
        }

        return number;
    }


    public static convertFromBasePrice(user: IUser, conversions: ICurrencyConversions, product: IProduct, onChange: (key: string, value: any) => void) {

        if (user.accountCurrency === Currency.Czk) {
            let price = CurrencyHelper
                .convertCurrency(conversions, user.accountCurrency, Currency.Eur, Product.getPriceByCurrency(user.accountCurrency, product))

            if (user.allowSmartPriceRound) {
                price = ProductHelper.roundPriceWithTolerance(price, user.priceExchangeRateDiffPercentageWarning);
            }
            Product.onChangePriceByCurrency(Currency.Eur, price, onChange)
        }
        else {
            let price = CurrencyHelper
                .convertCurrency(conversions, user.accountCurrency, Currency.Czk, Product.getPriceByCurrency(user.accountCurrency, product))

            if (user.allowSmartPriceRound) {
                price = ProductHelper.roundPriceWithTolerance(price, user.priceExchangeRateDiffPercentageWarning);
            }
            Product.onChangePriceByCurrency(Currency.Czk, price, onChange)
        }
    }

    public static onChangeAutoProcess(product: IProduct, key: string, value: any): IProduct {
        if ((key === 'bazosCZ' && product.bazosCzVisible && value === false) ||
            (key === 'bazosSK' && product.bazosSkVisible && value === false) ||
            (key === 'sbazar' && product.sbazarVisible && value === false) ||
            (key === 'sbazarAccountId' && !value)
        ) {
            Swal.fire({
                title: 'Inzerát bude mimo kontrolu',
                icon: 'warning',
                text: 'Vypnutím inzertního serveru u inzerátu nedojde k jeho odstranění. Inzerobot nebude tento inzerát na daném inzertním serveru nadále sledovat.',
                confirmButtonText: 'Rozumím',
                showCancelButton: false,
            })
        }

        if (key === 'sbazarAccountId' && value) {
            product.sbazar = true;
        }
        else if (key === 'sbazarAccountId') {
            product.sbazar = false;
        }
        if (key === 'facebookCatalogId') {
            product.facebookCatalogEnabled = value > 0;
        }
        if (key === 'vehicleBrand') {
            product.vehicleModel = null;
        }
        if (key === 'priceKc' || key === 'priceEur') {
            value = parseInt(value);
        }
        if (key === 'bazosCzSection') {
            product.bazosCzCategory = null;

            if (!product.bazosSkSection) {
                product.bazosSkSection = value;
            }
        }
        if (key === 'bazosSkSection') {
            product.bazosSkCategory = null;

            if (!product.bazosCzSection) {
                product.bazosCzSection = value;
            }
        }
        if (key === 'section') {
            value = parseInt(value);
        }

        if (key === 'bazosCzCategory') {
            if (!product.bazosSkCategory) {
                product.bazosSkCategory = value;
            }
        }

        if (key === 'bazosSkCategory') {
            if (!product.bazosCzCategory) {
                product.bazosCzCategory = value;
            }
        }

        if (key === 'kleinanzeigen' && value === true) {
            product.kleinanzeigenCustomContent = true;
        }
        if (key === 'kleinanzeigenCustomContent' && value === false) {
            product.kleinanzeigen = false;
        }
   
        product = {
            ...product,
            [key]: value
        };

        if (product.bazosSkPriceOption === 0) {
            product.bazosSkPriceOption = 1;
        }


        if (product.bazosCzPriceOption === 0) {
            product.bazosCzPriceOption = 1;
        }

        return product;
    }
}
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { OnlineServer } from "../../../../Enums/OnlineServer";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import CurrentUserStore from "../../../Stores/CurrentUserStore";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import SautoApiService from "../../../../Services/SautoApiService";
import InputText from "../../../UI/Input/InputText";
import { ValidationHelper } from "../../../Utils/ValidationHelper";
import { ISautoApiSettings } from "../../../../Interfaces/ISautoApiSettings";
import { toast } from "react-toastify";

interface IProps {
    currentUserStore?: CurrentUserStore
}

const sautoApiService = new SautoApiService();

export const SautoExportPage = inject('currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore.user;
    const isEnabledServer = user.uploaderServers.includes(OnlineServer.Sauto);
    const [settings, setSettings] = useState(user.sautoApiSettings);
    const loginInputRef = useRef<InputText>(null);
    const passwordInputRef = useRef<InputText>(null);
    const keyInputRef = useRef<InputText>(null);

    useEffect(() => {
        return () => {
            sautoApiService.cancelRequests();
        }
    }, [])

    const submit = () => {
        const inputs = [
            loginInputRef,
            passwordInputRef,
            keyInputRef,
        ];

        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            sautoApiService.postSettings(settings)
                .then(data => {
                    if (data.success) {
                        toast.success("Nastavení uloženo");
                    }
                })
        }
    }

    const onChange = (value: any, key: keyof ISautoApiSettings) => {
        setSettings({
            ...settings,
            [key]:value
        })
    }

    return (
        <CustomDocumentTitle title="Sauto - EXPORT">
            <PageTitleBox title="Sauto - EXPORT" />

            <PageTitleBox title="Nastavení" />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                           {isEnabledServer && <>

                            </>}

                            <InputText
                                ref={loginInputRef}
                                label="Login"
                                value={settings.login}
                                onChange={(val) => onChange(val, "login")}
                                required
                            /> 

                            <InputText
                                ref={passwordInputRef}
                                label="Heslo"
                                value={settings.password}
                                onChange={(val) => onChange(val, "password")}
                                required
                            /> 

                            <InputText
                                ref={keyInputRef}
                                label="Key"
                                value={settings.key}
                                onChange={(val) => onChange(val, "key")}
                                required
                            /> 

                            <button
                                className="btn btn-primary"
                                onClick={submit}
                            >
                                Uložit změny
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>)
}))
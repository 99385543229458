import { inject, observer } from "mobx-react";
import { useEffect, useState, useRef } from "react";
import { PageTitleBox } from "../../../Others/PageTitleBox";
import CustomDocumentTitle from "../../../Utils/CustomDocumentTitle";
import ProductApiService from "../../../../Services/ProductApiService";
import SelectBox from "../../../UI/Input/SelectBox";
import IOptionData from "../../../../Interfaces/IOptionData";
import ProductCollectionStore from "../../../Stores/ProductCollectionStore";
import IProductsExcelExportRequestDto from "../../../../Interfaces/Requests/Products/IProductsExcelExportRequestDto";
import { ValidationHelper } from "../../../Utils/ValidationHelper";
import { toast } from "react-toastify";

interface IProps {
    productCollectionStore?: ProductCollectionStore
}

const productApiService = new ProductApiService();

export const ExportExcelPage = inject('productCollectionStore')(observer((props: IProps) => {

    var productCollectionStore = props.productCollectionStore!;

    useEffect(() => {
        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    const [form, setForm] = useState<IProductsExcelExportRequestDto>({ productCollectionId: '' });
    const collectionRef = useRef<SelectBox>();

    let collectionOptions: IOptionData[] = [];

    var collections = productCollectionStore.list;
    collections.map(collection => {
        collectionOptions.push({
            label: collection.name,
            value: collection.id,
        });
    })

    const onChange = (key: string, val: any) => {
        setForm({
            ...form,
            [key]:val,
        })
    }

    const download = () => {
        const isValid = ValidationHelper.validateInputs([collectionRef]);

        if (isValid) {
            window.open(`/api/products/export/excel?productCollectionId=${form.productCollectionId}`, '_blank');
            toast.success('Export byl úspěšně zahájen.');
        } 
    }

    return (
        <>
            <CustomDocumentTitle title="Export do Excelu">
                <PageTitleBox title="Export do Excelu" />

                <div className="card">
                    <div className="card-body">
                        <p>Exportovaný soubor nelze zpět naimportovat do Inzerobota. Slouží pouze pro export. Maximum inzerátů je omezen na 100.</p>

                        <SelectBox
                            ref={collectionRef }
                            defaultValue="Vyberte kolekci k exportu"
                            label="Vyberte kolekci k exportu"
                            value={form.productCollectionId}
                            options={collectionOptions}
                            onChange={(val) => onChange('productCollectionId', val)}
                            required
                        />

                        <button className="btn btn-primary" onClick={download}>Stáhnout</button>
                    </div>
                </div>
            </CustomDocumentTitle>
        </>
    );
}))
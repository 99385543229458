import IProduct from "../Interfaces/IProduct";
import IResponseData from "../Interfaces/IResponseData";
import BaseApiService from "./BaseApiService";

export default class KleinanzeigenApiService extends BaseApiService {

    readonly baseUrl: string = "kleinanzeigen";

    public translate(product: IProduct): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/translate`, product, {})
            .then(response => {
                return response.data;
            })
    }
}
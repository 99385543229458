import { OnlineServer } from "../../Enums/OnlineServer";
import { ProductHelper } from "../../Helpers/ProductHelper";
import IProductServerInfo from "../../Interfaces/IProductServerInfo";

interface IProps {
    info: IProductServerInfo,
}

export const ProductServerTooltipContent = (props: IProps) => {

    const { info } = props;

    const serversOnOff = [
        OnlineServer.Website,
        OnlineServer.TipMoto,
        OnlineServer.FacebookShop,
        OnlineServer.AutobazarEu,
        OnlineServer.GoogleMerchant,
        OnlineServer.Kleinanzeigen,
    ];

    if ((serversOnOff.includes(info.server)) && info.enabled) {
        return <>Nastaveno</>
    }

    if (!info.enabled) {
        return <>
            Nenastaveno
        </>
    }
    if (info.error && ProductHelper.getFatalErrorTypes().includes(info.errorType)) {
        return <>
            Došlo k technické chybě
        </>
    }
    if (info.pendingCheck) {
        return <>
            Bude provedena kontrola
        </>
    }
    if (info.error) {
        return <>
            Inzerát má problémy, které vyžadují vaší pozornost
        </>
    }
    if (info.serverRequiredAction) {
        return <>
            Vyžadována akce - propojení
        </>
    }
    else if (info.pendingEdit) {
        return <>
            Inzerát bude upraven 
        </>
    }
    else if (info.pendingDelete) {
        return <>
            Inzerát bude smazán
        </>
    }
    if (info.pendingUpload) {
        if (info.isVisible) {
            return <>Inzerát bude obnoven</>
        }
        return <>
            Inzerát bude nahrán
        </>
    }
    if (info.isVisible) {
        return <>
            Inzerát je vystaven
        </>
    }
    if (!info.isVisible) {
        return <>
            Inzerát není vystaven
        </>
    }
}
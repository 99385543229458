import IResponseData from "../Interfaces/IResponseData";
import { ISautoApiSettings } from "../Interfaces/ISautoApiSettings";
import BaseApiService from "./BaseApiService";

export default class SautoApiService extends BaseApiService {

    readonly baseUrl: string = "sauto";

    public postSettings(settings: ISautoApiSettings): Promise<IResponseData> {
        return this.postRequest(`${this.baseUrl}/settings`,
            {
                ...settings
            },
            {})
            .then(response => {
                return response.data;
            })
    }
}
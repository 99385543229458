import IProduct from "../../../Interfaces/IProduct"
import { SuccessAlert } from "../../Others/Alerts/SuccessAlert";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";
import { NotSetupedAlert } from "./NotSetupedAlert";

interface IProps {
    product: IProduct,
}

export const KleinanzeigenTabContent = (props: IProps) => {

    const { product } = props;

    return (
        <>
            {product.kleinanzeigen &&
                <>
                    <SuccessAlert text="Nastaveno" />

                    <InputText
                        readonly
                        value={product.kleinanzeigenName}
                        label="Název"
                    />

                    <Textarea
                        disabled
                        value={product.kleinanzeigenDescription}
                        label="Popis"
                    />
                </>
            }
            {!product.kleinanzeigen && <NotSetupedAlert />}
        </>)
}
import React, { useEffect, useImperativeHandle, useRef } from "react";
import IProduct from "../../../Interfaces/IProduct";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import { IField } from "../../../Interfaces/IField";
import { Cpu } from "react-bootstrap-icons";
import ProductApiService from "../../../Services/ProductApiService";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import { TinyMCEEditor } from "../../UI/TinyMCEEditor";
import { OnlineServer } from "../../../Enums/OnlineServer";

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
    onBatchUpdate(values: { [key: string]: any }): void,
    validChanged?(isValid: boolean): void,
}

const productApiService = new ProductApiService();

export const WebsiteEditor = React.forwardRef<IValidationFormRef, IProps>(({ product, onChange, validChanged, onBatchUpdate }, ref) => {
    const inputName = useRef<InputText>(null);
  
    useEffect(() => {
        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    const getInputs = () => {
        return [inputName];
    };

    const validate = (): boolean => {
        const inputs = getInputs();
        const isValid = inputs.every(input => input.current?.validate());
        if (validChanged) {
            validChanged(isValid);
        }
        return isValid;
    };

    useImperativeHandle(ref, () => ({
        getInputs,
        validate
    }));

    const generateContent = () => {
        productApiService.generateContent(product, OnlineServer.Website)
            .then(data => {
                const fields = data.others.get("fields") as IField;

                // Create a temporary object to hold all changes
                const updatedFields: { [key: string]: any } = {};

                Object.entries(fields).forEach(([key, value]) => {
                    updatedFields[key] = value;
                });
                // Apply all changes at once
                onBatchUpdate(updatedFields);
            });
    };

    return (
        <>
            <div className="pb-3 pt-1">
                <CheckBox
                    checked={product.websiteCustomContent}
                    onChange={(value) => onChange(value, 'websiteCustomContent')}
                    label="Nastavit jiný obsah"
                />
            </div>

            <button
                className="btn btn-ai float-right"
                onClick={generateContent}
            >
                <Cpu /> Generovat obsah
            </button>

            <div className={product.websiteCustomContent ? 'd-block' : 'd-none'}>
                <InputText
                    ref={inputName}
                    label="Nadpis"
                    onChange={(value) => onChange(value, 'websiteName')}
                    value={product.websiteName}
                    required
                    maxLength={ProductHelper.getMaxNameCharsCount()}
                  //  onBlur={this.checkValidate.bind(this)}
                />

                <TinyMCEEditor
                    label="Popis"
                    onChange={(value) => onChange(value, 'websiteDescription')}
                    required
                    value={product.websiteDescription}
                    height={500}
                    toolbar={'undo redo | formatselect | bold italic underline | ' +
                        'forecolor backcolor | fontsizeselect | fontsize |' +
                        'bullist numlist outdent indent | removeformat | ' +
                        'h2 h3 h4 h5 h6'}
                />
            </div>
        </>
    );
});
import { orderBy } from "lodash";
import { OnlineServer } from "../../Enums/OnlineServer";
import IOptionData from "../../Interfaces/IOptionData";
import { IProductFilter } from "../../Interfaces/IProductFilter"
import InputText from "../UI/Input/InputText"
import SelectBox from "../UI/Input/SelectBox";
import SelectCheckBox from "../UI/Input/SelectCheckBox";
import { OnlineServerHelper } from "../Utils/OnlineServerHelper";
import ServerAccountStore from "../Stores/ServerAccountStore";
import { inject, observer } from "mobx-react";
import CheckBox from "../UI/Input/CheckBox";

interface IProps {
    filter: IProductFilter,
    onChange(key: string, value: any): void,
    collectionsOptions: IOptionData[],
    onSubmit(): void,
    serverAccountStore?: ServerAccountStore,
}

export const ProductsFilter = inject('serverAccountStore')(observer((props: IProps) => {
    const { filter, onChange, collectionsOptions } = props;
    const serverAccounts = props.serverAccountStore.list;

    let accountsOptions: IOptionData[] = [
        { label: 'Nevybráno', value: '' }
    ]

    orderBy(serverAccounts, acc => acc.onlineServer).forEach(acc => {
        accountsOptions.push({ label: `${OnlineServerHelper.getName(acc.onlineServer)} - ${acc.accountName || ''} ${acc.userName}`, value: acc.id })
    })
   
    let channelOptions: IOptionData[] = [
        { label: 'Nevybráno', value: 'default' }
    ]

    let servers: IOptionData[]  = [
        { label: 'Web', value: OnlineServer.Website },
        { label: 'Facebook obchod', value: OnlineServer.FacebookShop },
        { label: 'Facebook Marketplace', value: OnlineServer.FacebookMarketplace },
      //  { label: 'TipMoto', value: OnlineServer.TipMoto },
        { label: 'Bazoš.cz', value: OnlineServer.BazosCz },
        { label: 'Bazoš.sk', value: OnlineServer.BazosSk },
        { label: 'Sbazar', value: OnlineServer.Sbazar },
        { label: 'Autobazar.eu', value: OnlineServer.AutobazarEu },
        { label: 'Google Merchant', value: OnlineServer.GoogleMerchant },
        { label: 'Kleinanzeigen', value: OnlineServer.Kleinanzeigen },
    ]

    servers = orderBy(servers, server => [server.label], ['asc']);

    servers.map(s => {
        channelOptions.push(s);
    })
    
    return (
        <div className="card m-0">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <InputText
                            label="Hledat"
                            value={filter.search}
                            onChange={(value) => onChange('search', value)}
                            onEnter={() => props.onSubmit()}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <SelectBox
                            label="Účet"
                            value={filter.serverAccountId}
                            onChange={(value) => onChange('serverAccountId', value)}
                            options={accountsOptions}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <SelectBox
                            label="Kolekce"
                            value={filter.productCollectionId}
                            onChange={(value) => onChange('productCollectionId', value)}
                            options={collectionsOptions}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <SelectCheckBox
                            value={filter.enabled}
                            label="Stav"
                            onChange={(value) => onChange('enabled', value)}
                            labelFalse="Staženo z prodeje"
                            labelTrue="V prodeji"
                            defaultValue="Vyberte"
                            required={false}
                            allowNull={false}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <SelectBox
                            label="Prodejní kanál"
                            value={filter.onlineServer}
                            onChange={(value) => onChange('onlineServer', value)}
                            options={channelOptions}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <SelectBox
                            label="Stav prod. kanálu"
                            value={filter.onlineServerState}
                            onChange={(value) => onChange('onlineServerState', value)}
                            options={OnlineServerHelper.getOnlineServerStatesOptions(filter.onlineServer)}
                            disabled={!filter.onlineServer || filter.onlineServer === null}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <CheckBox
                            label="Pouze rezervace"
                            checked={filter.reserved}
                            onChange={(value) => onChange('reserved', value)}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <button onClick={() => props.onSubmit()} className="btn btn-primary btn-block">Hledat</button>
                    </div>
                </div>
            </div>
        </div>
    )
}))
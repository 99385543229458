import { useEffect, useState } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ProductType } from "../../Enums/ProductType";
import IProduct from "../../Interfaces/IProduct"
import ProductApiService from "../../Services/ProductApiService";
import { CarSalesCard } from "./Cars/CarSalesCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ProductDeleteModal } from "./ProductDeleteModal";
import ProductCollectionStore from "../Stores/ProductCollectionStore";
import { inject, observer } from "mobx-react";
import { MigrateProductToAnotherCollectionModal } from "./MigrateProductToAnotherCollectionModal";

interface IProps {
    product: IProduct,
    showGoToCollectionButton?: boolean,
    showDeleteButton?: boolean,
    showChangeCollection?: boolean,
    productCollectionStore?: ProductCollectionStore,
}

const productApiService = new ProductApiService();

export const ProductOptionsDropdown = inject('productCollectionStore')(observer((props: IProps) => {
    const { product, showGoToCollectionButton, showChangeCollection } = props;

    useEffect(() => {
        return () => {
            productApiService.cancelRequests();
        }
    }, [])

    function handleMakeDisabled() {
        Swal.fire({
            title: `${product.name}`,
            html: `Inzerát smažeme z inzertních portálů, ale můžete jej znovu kdykoli vystavit`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ano, stáhnout z prodeje ',
            cancelButtonText: 'Zrušit'
        }).then((result) => {
            if (result.value) {
                productApiService.disable(product.id);
            }
        })
    }

    function handleMakeEnabled() {
        productApiService.enable(product.id);
    }

    function handleDownloadSalesCard() {
        toast.info('Karta byla stažena do vašeho počítače');
    }

    function handleLock() {
        productApiService.lock(product.id);
    }

    function handleUnlock() {
        productApiService.unlock(product.id);
    }

    const markAsReserved = () => {
        productApiService.markAsReserved(product.id)
            .then((data) => {
                if (data.success) {
                    // toast.success('Inzerát byl označen jako rezervován');
                }
            });
    }

    const markAsNotReserved = () => {
        productApiService.markAsNotReserved(product.id)
            .then((data) => {
                if (data.success) {

                }
            });
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [changeCollection, setChangeCollection] = useState(false);
    const collection = props.productCollectionStore.getById(product.productCollectionId);
    const collections = props.productCollectionStore.getByUserId(product.userId);
    return (
        <>
            {changeCollection && <MigrateProductToAnotherCollectionModal
                product={product}
                onHide={() => setChangeCollection(false)}
                currentCollection={collection}
            />}

            {showDeleteModal && <ProductDeleteModal
                onHide={() => setShowDeleteModal(false)}
                product={product}
            />}

            <Dropdown>
                <Dropdown.Toggle variant="secondary" data-boundary="window" className="btn-sm ml-1 h-100" id="dropdownProductOptions">
                    <span className="mr-2">Možnosti</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {product.enabled ?
                        <OverlayTrigger
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Inzerát smažeme z inzertních serverů, ale můžete jej kdykoliv vystavit znovu
                                </Tooltip>
                            }
                        >
                            <Dropdown.Item onClick={() => handleMakeDisabled()}>
                                Stáhnout z prodeje
                            </Dropdown.Item>
                        </OverlayTrigger>
                        : null}

                    {!product.enabled ?
                        <Dropdown.Item onClick={() => handleMakeEnabled()}>
                            Vrátit do prodeje
                        </Dropdown.Item> : null}

                    {showChangeCollection && <OverlayTrigger
                        placement={'left'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Přesunout do jiné kolekce
                            </Tooltip>
                        }
                    >
                        <Dropdown.Item onClick={() => setChangeCollection(true)}>
                            Změna kolekce
                        </Dropdown.Item>
                    </OverlayTrigger>}

                    {showGoToCollectionButton &&
                        <Dropdown.Item as="div">
                            <Link to={`/nahravac/kolekce/${collection.id}/prehled`}>
                                Přejít do kolekce
                            </Link>
                        </Dropdown.Item>}

                    {product.type === ProductType.Car &&
                        <div onClick={() => handleDownloadSalesCard()}>
                            <PDFDownloadLink
                                className="dropdown-item"
                                fileName={`Prodejní karta ${product.name}.pdf`}
                                document={<CarSalesCard product={product} />}
                            >
                                Stáhnout prodejní kartu
                            </PDFDownloadLink>
                        </div>
                    }

                    {product.autoImporterId && product.lock === false &&
                        <OverlayTrigger
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Zamknout proti přepsání
                                </Tooltip>
                            }
                        >
                            <Dropdown.Item onClick={() => handleLock()}>
                                Zamknout
                            </Dropdown.Item>
                        </OverlayTrigger>}

                    {!product.reserved &&
                        <Dropdown.Item onClick={() => markAsReserved()}>
                            Označit jako rezervováno
                        </Dropdown.Item>
                    }

                    {product.reserved &&
                        <Dropdown.Item onClick={() => markAsNotReserved()}>
                            Zrušit rezervaci
                        </Dropdown.Item>
                    }

                    {product.autoImporterId && product.lock === true &&
                        <OverlayTrigger
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Zrušit ochranu proti přepsání
                                </Tooltip>
                            }
                        >
                            <Dropdown.Item onClick={() => handleUnlock()}>
                                Odemknout
                            </Dropdown.Item>
                        </OverlayTrigger>}

                    {props.showDeleteButton &&
                        <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                            <span className="text-danger"> <i className="far fa-trash-alt mr-2 d-none d-md-inline"></i>
                                Smazat
                            </span>
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </>)
}))
import { inject, observer } from "mobx-react";
import CurrencyHelper from "../../Helpers/CurrencyHelper";
import { IAccountTransaction } from "../../Interfaces/IAccountTransaction";
import { SeparateThousands } from "../Others/SeparateThousands";
import { ShowDate } from "../Others/ShowDate";
import CurrentUserStore from "../Stores/CurrentUserStore";

interface IProps {
    transaction: IAccountTransaction,
    currentUserStore?: CurrentUserStore,
}

export const TransactionTableRow = inject('currentUserStore')(observer((props: IProps) => {
    const transaction = props.transaction;
    const user = props.currentUserStore.user;

    return (
        <tr>
            <td>
                <ShowDate date={transaction.date} />
            </td>

            <td>
                {transaction.topUp ? <span className="badge badge-success">Příjem</span> : <span className="badge badge-danger">Výdaj</span>}
            </td>

            <td>
                <p>{transaction.description}</p>
            </td>

            <td>
                <SeparateThousands number={transaction.amount} roundPrecision={4} /> {CurrencyHelper.getSymbol(user.accountCurrency)}
            </td>

            <td>

            </td>
        </tr>
    )
}))
import { PageTitleBox } from '../../../Others/PageTitleBox';
import CurrentUserStore from '../../../Stores/CurrentUserStore';
import CustomDocumentTitle from '../../../Utils/CustomDocumentTitle';
import { MyCompanyGooglePlacesCard } from '../../../Integrations/GooglePlaces/MyCompanyGooglePlacesCard';
import { inject, observer } from 'mobx-react';


interface IProps {
    currentUserStore?: CurrentUserStore,
}

export const GoogleMapsPage = inject('currentUserStore')(observer((props: IProps) => {
    const user = props.currentUserStore.user;

    return (
        <CustomDocumentTitle title="Google Mapy">
            <PageTitleBox title="Google Mapy" />
            <div className="card mb-0">
                <div className="card-body">
                    <MyCompanyGooglePlacesCard
                        user={user}
                        onUserUpdated={(user) => { }}
                    />
                </div>
            </div>
        </CustomDocumentTitle>)
}))
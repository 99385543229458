import { filter } from 'lodash';
import { inject, observer } from 'mobx-react';
import { ProformaInvoiceState } from '../../Enums/ProformaInvoiceState';
import LoadingScreen from '../Screens/LoadingScreen';
import AutoImporterStore from '../Stores/AutoImporterStore';
import CurrentUserStore from '../Stores/CurrentUserStore';
import ProductCollectionStore from '../Stores/ProductCollectionStore';
import ProductImageStore from '../Stores/ProductImageStore';
import ProductStore from '../Stores/ProductStore';
import ProformaInvoiceStore from '../Stores/ProformaInvoiceStore';
import WebsiteStore from '../Stores/WebsiteStore';
import { AutoImporterWidget } from '../Uploader/Dashboard/AutoimporterWidget';
import { DashboardWidgets } from '../Uploader/Dashboard/DashboardWidgets';
import { NotPaidInvoicesWidget } from '../Uploader/Dashboard/NotPaidInvoicesWidget';
import { WebsitesWidget } from '../Uploader/Dashboard/WebsitesWidget';
import CustomDocumentTitle from '../Utils/CustomDocumentTitle';
import { SalesChart } from '../Products/Charts/SalesChart';
import { Link } from 'react-router-dom';
import { BoxArrowInUpRight } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import UserApiService from '../../Services/UserApiService';
import { PageTitleBox } from '../Others/PageTitleBox';
import { IMonthlySalesReport } from '../../Interfaces/IMonthlySalesReport';
import GlobalSettingsStore from '../Stores/GlobalSettingsStore';

interface IProps {
    productStore?: ProductStore,
    productCollectionStore?: ProductCollectionStore,
    productImageStore?: ProductImageStore,
    currentUserStore?: CurrentUserStore,
    autoImporterStore?: AutoImporterStore,
    proformaInvoiceStore?: ProformaInvoiceStore,
    websiteStore?: WebsiteStore,
    history?: any,
    globalSettingsStore?: GlobalSettingsStore,
}

const userApiService = new UserApiService();

export const DashboardPage =
    inject(
        'productStore',
        'productCollectionStore',
        'productImageStore',
        'currentUserStore',
        'autoImporterStore',
        'proformaInvoiceStore',
        'websiteStore',
        'globalSettingsStore')
        (observer((props: IProps) => {
            const [loading, setLoading] = useState(true);
            const [productsPendingCount, setProductsPendingCount] = useState(0);
            const [monthlySalesReport, setMonthlySalesReport] = useState<IMonthlySalesReport[]>([])

            useEffect(() => {
                userApiService.getDashboard()
                    .then(data => {
                        const productsPendingCount = data.others.get("productsPendingCount");
                        setMonthlySalesReport(data.others.get("sales"));
                        setLoading(false);
                        setProductsPendingCount(productsPendingCount);
                    })

                return () => {
                    userApiService.cancelRequests();
                }
            }, [])

            const user = props.currentUserStore.user;
            const proformaInvoices = filter(props.proformaInvoiceStore.getByUserId(user.id), { paid: false, state: ProformaInvoiceState.ToPay });
            const websites = props.websiteStore.getByUserId(user.id);
            const autoimporters = props.autoImporterStore.getByUserId(user.id);
            const settings = props.globalSettingsStore.settings;
  
            return (
                <>
                    <CustomDocumentTitle title={`Nástěnka`}>
                        <PageTitleBox title="Nástěnka" />

                        {loading ? <LoadingScreen /> : <>
                            <>
                                <DashboardWidgets
                                    user={user}
                                />

                                <div className="row no-gutters">
                                    <div className="col-lg-3">
                                        <div className="row no-gutters">
                                            {proformaInvoices.length > 0 &&
                                                <div className="col-12 col-md-6 col-lg-12 pr-1">
                                                    <NotPaidInvoicesWidget
                                                        proformaInvoices={proformaInvoices}
                                                    />
                                                </div>}

                                            {user.autoImporterEnabled && <>
                                                {autoimporters.map(autoimporter => {
                                                    if (user.autoImporterSourceTypes.includes(autoimporter.sourceType)) {
                                                        return (<div key={autoimporter.id} className="col-12 col-md-6 col-lg-12 pr-1">
                                                            <AutoImporterWidget
                                                                autoImporter={autoimporter}
                                                            />
                                                        </div>)
                                                    }
                                                })}
                                            </>}

                                            <div className="col-12 col-md-6 col-lg-12 pr-1">
                                                <WebsitesWidget
                                                    websites={websites}
                                                />
                                            </div>

                                            {/*      <div className="col-12 col-md-6 col-lg-12 pr-1 d-none d-lg-block">
                                                <div className="card mb-1">
                                                   
                                                    <div className="card-body">
                                                        <img src="Templates\Admin\images\tree.png" loading="lazy" className="img-fluid" />
                                                    </div>
                                                </div>

                                            </div>*/}
                                        </div>
                                    </div>

                                    <div className="col-lg-9 pr-1 d-lg-block mb-1">
                                        <div className="card d-flex flex-column mb-1">
                                            <div className="card-header" style={{ zIndex: 1 }}>
                                                Prodeje <Link to="/obchod/prehled">
                                                    <span className="position-absolute" style={{ right: '10px', top: '15px', color: 'darkgray' }}>
                                                        Obchodní přehled <BoxArrowInUpRight />
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="card-body">
                                                <SalesChart sales={monthlySalesReport} currency={user.accountCurrency} />
                                            </div>
                                        </div>

                                        {settings.news && <div className="card d-flex flex-column mb-1">
                                            <div className="card-header" style={{ zIndex: 1 }}>
                                                Inzerobot - aktuality
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: settings.news }} className="card-body">

                                            </div>
                                        </div>}

                                        <small>
                                            Inzerátů k synchronizaci exportu: {productsPendingCount}. Tento údaj nemusí zahrnovat všechny inzertní servery.
                                        </small>
                                    </div>
                                </div>
                            </>
                        </>}
                    </CustomDocumentTitle>
                </>
            )
        }))


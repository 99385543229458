

export enum EquipmentCategory {
    SafetySystems = 1,
    AssistanceSystems = 2,
    VehicleSecurity = 3,
    InteriorEquipmentAndComfort = 4,
    Seats = 5,
    LightingTechnology = 6,
    Exterior = 7,
    DriveAndChassis = 8,
    OnboardSystemsAndConnectivity = 9,
    Other = 10,
}
import { OnlineServer } from "../../Enums/OnlineServer";
import { OnlineServerState } from "../../Enums/OnlineServerState";
import { ProductSubcategory } from "../../Enums/ProductSubcategory";
import { IContentRules } from "../../Interfaces/IContentRules";
import IOptionData from "../../Interfaces/IOptionData";
import IProduct from "../../Interfaces/IProduct";

export class OnlineServerHelper {

    static getName(server: OnlineServer): string {
        switch (server) {

            case OnlineServer.BazosCz:
                return "Bazoš.cz";

            case OnlineServer.BazosSk:
                return "Bazoš.sk";

            case OnlineServer.Sbazar:
                return "Sbazar.cz";

            case OnlineServer.AutoStranky:
                return "AutoStránky";

            case OnlineServer.Website:
                return "Web";

            case OnlineServer.FacebookShop:
                return "Facebook";

            case OnlineServer.TipMoto:
                return "TipMoto";

            case OnlineServer.FacebookMarketplace:
                return "FBMP";

            case OnlineServer.Tipcars:
                return "Tipcars.cz";

            case OnlineServer.Sauto:
                return "Sauto.cz";

            case OnlineServer.AutobazarEu:
                return "Autobazar.eu";

            case OnlineServer.GoogleMerchant:
                return "Google Nákupy";

            case OnlineServer.Kleinanzeigen:
                return "Kleinanzeigen";
        }
    }

    static getContentRules(server: OnlineServer): IContentRules {
        switch (server) {

            case OnlineServer.BazosCz:
                return {
                    canContainsPhoneNumber: true,
                    canContainsEmail: true,
                    canContainsUrl: false
                };


            case OnlineServer.BazosSk:
                return {
                    canContainsPhoneNumber: true,
                    canContainsEmail: true,
                    canContainsUrl: false
                };

            case OnlineServer.Sbazar:
                return {
                    canContainsPhoneNumber: false,
                    canContainsEmail: false,
                    canContainsUrl: false
                };

            default:
                return {
                    canContainsPhoneNumber: true,
                    canContainsEmail: true,
                    canContainsUrl: true
                };
        }
    }

    static getMaxImagesCount(server: OnlineServer): number {
        switch (server) {

            case OnlineServer.BazosCz:
                return 20;


            case OnlineServer.BazosSk:
                return 20;

            case OnlineServer.Sbazar:
                return 20;

            default:
                return 50;
        }
    }

    static getOnlineServerStatesOptions(server: OnlineServer): IOptionData[] {
        let options: IOptionData[] = [{ label: 'Nevybráno', value: '' }];

        if (!server) {
            return [];
        }
        if (server === OnlineServer.BazosCz ||
            server === OnlineServer.BazosSk ||
            server === OnlineServer.Sbazar ||
            server === OnlineServer.FacebookMarketplace) {
            options.push({ label: 'Vystaveno', value: OnlineServerState.Visible });
            options.push({ label: 'Nevystaveno', value: OnlineServerState.NotVisible });
            options.push({ label: 'Nenastaveno', value: OnlineServerState.NotSet });
            options.push({ label: 'Odmítnuto', value: OnlineServerState.Rejected });
        }
        else if (server === OnlineServer.FacebookShop ||
            server === OnlineServer.TipMoto ||
            server === OnlineServer.Website ||
            server === OnlineServer.AutobazarEu ||
            server === OnlineServer.GoogleMerchant ||
            server === OnlineServer.Kleinanzeigen) {
            options.push({ label: 'Ano', value: OnlineServerState.Visible });
            options.push({ label: 'Ne', value: OnlineServerState.NotSet });
        }
        return options;
    }

    static getNameByServer(product: IProduct, server: OnlineServer): string {
        switch (server) {

            case OnlineServer.BazosCz:
                return product.bazosCzUseDescription ? product.bazosCzName : product.name;

            case OnlineServer.BazosSk:
                return product.bazosSkUseDescription ? product.bazosSkName : product.name;

            case OnlineServer.Sbazar:
                return product.sbazarUseDescription ? product.sbazarName : product.name;

            case OnlineServer.FacebookShop:
                return product.facebookCustomContent ? product.facebookName : product.name;

            case OnlineServer.TipMoto:
                return product.tipMotoCustomContent ? product.tipMotoName : product.name;

            case OnlineServer.Website:
                return product.websiteCustomContent ? product.websiteName : product.name;

            case OnlineServer.FacebookMarketplace:
                return product.facebookMarketplaceCustomContent ? product.facebookMarketplaceName : product.name;

            default:
                return product.name;
        }
    }

    static getNotSupportedDetailsServers(): OnlineServer[] {

        const unsupportedDetails: OnlineServer[] = [
            OnlineServer.Website,
            OnlineServer.FacebookShop,
            OnlineServer.TipMoto,
            OnlineServer.AutobazarEu,
            OnlineServer.GoogleMerchant,
        ];

        return unsupportedDetails;
    }

    static getSupportedServersCategory(): OnlineServer[] {

        return [
            OnlineServer.BazosCz,
            OnlineServer.BazosSk,
            OnlineServer.FacebookShop,
            OnlineServer.Sbazar,
        ];
    }

    static getEnabledServers(product: IProduct): OnlineServer[] {
        const servers: OnlineServer[] = [];

        if (product.tipMoto) {
            servers.push(OnlineServer.TipMoto);
        }

        if (product.autobazarEu) {
            servers.push(OnlineServer.AutobazarEu);
        }

        if (product.bazosCZ) {
            servers.push(OnlineServer.BazosCz);
        }

        if (product.bazosSK) {
            servers.push(OnlineServer.BazosSk);
        }

        if (product.sbazar) {
            servers.push(OnlineServer.Sbazar);
        }

        if (product.websiteId) {
            servers.push(OnlineServer.Website);
        }

        if (product.facebookCatalogEnabled) {
            servers.push(OnlineServer.FacebookShop);
        }

        if (product.facebookMarketplaceEnabled) {
            servers.push(OnlineServer.FacebookMarketplace);
        }
        return servers;
    }

    public static isServerSupported(product: IProduct, server: OnlineServer): boolean {

        switch (server) {
            case OnlineServer.AutobazarEu:
                return this.isAutobazarEuSupported(product);

            case OnlineServer.GoogleMerchant:
                return true;

            default:
        }

        return false;
    }

    public static isAutobazarEuSupported(product: IProduct): boolean {

        const supportedCat = [
            ProductSubcategory.AluminumWheels,
            ProductSubcategory.AluminumWheelsPlusTires,
            ProductSubcategory.Tires
        ];

        if (supportedCat.includes(product.subcategory)) {
            return true;
        }
        return false;
    }

    public static getBadge(server: OnlineServer) {
        switch (server) {

            case OnlineServer.BazosCz:
                return (
                    <span className="badge badge-bazos">Bazoš.cz</span>
                )

            case OnlineServer.BazosSk:
                return (
                    <span className="badge badge-bazos">Bazoš.sk</span>
                )

            case OnlineServer.Sbazar:
                return (
                    <span className="badge badge-sbazar">Sbazar.cz</span>
                )

            case OnlineServer.AutoStranky:
                return (
                    <span className="badge badge-autostranky">AutoStranky</span>
                )
        }
    }
}
import React, { useRef } from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IProduct from "../../../Interfaces/IProduct";
import InputText from "../../UI/Input/InputText";
import IInputField from "../../../Interfaces/IInputField";
import { SectionTitle } from "../../Others/SectionTitle";
import SelectBox from "../../UI/Input/SelectBox";
import InputNumber from "../../UI/Input/InputNumber";
import { CountryHelper } from "../../../Helpers/CountryHelper";
import InputDouble from "../../UI/Input/InputDouble";
import Product from "../../../Models/Product";
import SmartSelectBox from "../../UI/Input/SmartSelectBox";
import { inject, observer } from "mobx-react";
import { listsStore } from "../../../App";
import { ListType } from "../../../Enums/ListType";
import ListsStore from "../../Stores/ListsStore";
import IOptionData from "../../../Interfaces/IOptionData";
import { filter } from "lodash";

interface IProps {
    product: IProduct,
    onChange(key: string, value: any): void,
    requiredFields: string[],
    listsStore?: ListsStore,
}

export const CarsFormCard = inject('listsStore')(observer(React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const {
        product,
        onChange,
        requiredFields
    } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
            vehicleBrandRef,
            vehicleModelRef,
            vehicleVinRef,
            vehicleBodyRef,
            vehicleConditionRef,
            vehicleMiliageRef,
            vehicleYearRef,
            // todo mot
            vehicleTransmissionRef,
            vehicleEmissionClassRef,
            vehicleFuelTypeRef,
            vehicleFuelConsumptionCombinatedRef,
            vehicleCubicCapacityRef,
            vehiclePowerRef,

            vehicleDoorCountRef,
            vehicleNumberOfSeatsRef,
            vehicleColorRef,
            vehicleWeightRef,

            vehicleNumberOfVehicleOwnersRef,
            vehicleCountryOriginRef,
            vehicleDriveRef,
        ]
    }

    const vehicleBrandRef = useRef<SmartSelectBox>(null);
    const vehicleModelRef = useRef<SmartSelectBox>(null);
    const vehicleVinRef = useRef<InputText>(null);
    const vehicleBodyRef = useRef<SelectBox>(null);
    const vehicleConditionRef = useRef<SelectBox>(null);
    const vehicleMiliageRef = useRef<InputNumber>(null);
    const vehicleYearRef = useRef<SelectBox>(null);
    const vehicleTransmissionRef = useRef<SelectBox>(null);
    const vehicleEmissionClassRef = useRef<SelectBox>(null);
    const vehicleFuelTypeRef = useRef<SelectBox>(null);
    const vehicleFuelConsumptionCombinatedRef = useRef<InputDouble>(null);
    const vehicleCubicCapacityRef = useRef<InputNumber>(null);
    const vehiclePowerRef = useRef<InputNumber>(null);
    const vehicleDoorCountRef = useRef<InputNumber>(null);
    const vehicleNumberOfSeatsRef = useRef<InputNumber>(null);
    const vehicleColorRef = useRef<SelectBox>(null);
    const vehicleWeightRef = useRef<InputNumber>(null);
    const vehicleNumberOfVehicleOwnersRef = useRef<InputNumber>(null);
    const vehicleCountryOriginRef = useRef<SelectBox>(null);
    const vehicleDriveRef = useRef<SelectBox>(null);

    let vehicleModels: IOptionData[];
    if (product.vehicleBrand) {
        vehicleModels = listsStore.getBrandModelsOptions(product.vehicleBrand, [product.vehicleType]);
    }

    let vehicleBrands = listsStore.getBrandsOptions([product.vehicleType]);

    if (product.autobazarEu) {
        vehicleModels = filter(vehicleModels, m => m.value !== 1); // ostatni
        vehicleBrands = filter(vehicleBrands, b => b.value !== 147);  //147
    }
  
    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="Technické údaje" className="my-0" />
            </div>
            <div className="card-body">
                <div className="pb-2">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <label className="section-title pb-2">Údaje o vozidle</label>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3 col-lg-6">
                                    <SmartSelectBox
                                        ref={vehicleBrandRef}
                                        required={true}
                                        label="Značka"
                                        onChange={(value,  key) => onChange(key, parseInt(value))}
                                        value={product.vehicleBrand}
                                        options={vehicleBrands}
                                        uid="vehicleBrand"
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SmartSelectBox
                                        ref={vehicleModelRef}
                                        required={true}
                                        label="Model"
                                        onChange={(value, key) => onChange(key, parseInt(value))}
                                        value={product.vehicleModel}
                                        options={vehicleModels}
                                        uid="vehicleModel"
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputText
                                        ref={vehicleVinRef}
                                        label="VIN"
                                        onChange={(val) => onChange('vehicleVin', val)}
                                        value={product.vehicleVin}
                                        length={17}
                                        required={requiredFields.includes("vehicleVin")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleBodyRef}
                                        defaultValue="- nevybráno -"
                                        label="Karoserie"
                                        onChange={(val) => onChange('vehicleBody', val)}
                                        value={product.vehicleBody}
                                        options={Product.getVehicleBodyOptions(product.vehicleType)}
                                        required={requiredFields.includes("vehicleBody")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        required
                                        ref={vehicleConditionRef}
                                        defaultValue="- nevybráno -"
                                        label="Stav"
                                        onChange={(val) => onChange('vehicleCondition', val)}
                                        value={product.vehicleCondition}
                                        options={Product.getVehicleConditionOptions()}
                                    //   required={requiredFields.includes("vehicleCondition")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehicleMiliageRef}
                                        label="Tachometr (km)"
                                        onChange={(val) => onChange('vehicleMileage', val)}
                                        value={product.vehicleMileage}
                                        required={requiredFields.includes("vehicleMileage")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleYearRef}
                                        defaultValue="- nevybráno -"
                                        label="Rok první registrace"
                                        onChange={(val) => onChange('vehicleYear', val)}
                                        value={product.vehicleYear}
                                        options={Product.getVehicleYearOptions()}
                                        required={requiredFields.includes("vehicleYear")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <label>Platnost STK</label>
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <SelectBox
                                                defaultValue="Měsíc"
                                                label=""
                                                onChange={(val) => onChange('vehicleMOTMonth', val)}
                                                value={product.vehicleMOTMonth}
                                                options={Product.getVehicleMotMonthOptions()}
                                                required={requiredFields.includes("vehicleMOTMonth")}
                                            />
                                        </div>
                                        <div className="col">
                                            <SelectBox
                                                defaultValue="Rok"
                                                label=""
                                                onChange={(val) => onChange('vehicleMOTYear', val)}
                                                value={product.vehicleMOTYear}
                                                options={Product.getVehicleMotYearOptions()}
                                                required={requiredFields.includes("vehicleMOTYear")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <label className="section-title pb-2">Motorizace</label>
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleTransmissionRef}
                                        defaultValue="- nevybráno -"
                                        label="Převodovka"
                                        onChange={(val) => onChange('vehicleTransmission', val)}
                                        value={product.vehicleTransmission}
                                        options={Product.getVehicleTransmissionOptions()}
                                        required={requiredFields.includes("vehicleTransmission")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleEmissionClassRef}
                                        defaultValue="- nevybráno -"
                                        label="Plní EURO"
                                        onChange={(val) => onChange('vehicleEmissionClass', val)}
                                        value={product.vehicleEmissionClass}
                                        options={Product.getEmissionClassOptions()}
                                        required={requiredFields.includes("vehicleEmissionClass")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleFuelTypeRef}
                                        defaultValue="- nevybráno -"
                                        label="Palivo"
                                        onChange={(val) => onChange('vehicleFuelType', val)}
                                        value={product.vehicleFuelType}
                                        options={Product.getFuelTypeOptions()}
                                        required={requiredFields.includes("vehicleFuelType")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputDouble
                                        ref={vehicleFuelConsumptionCombinatedRef}
                                        label="Komb. spotř. (l/100 km)"
                                        onChange={(val) => onChange('vehicleFuelConsumptionCombinated', val)}
                                        value={product.vehicleFuelConsumptionCombinated}
                                        required={requiredFields.includes("vehicleFuelConsumptionCombinated")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehicleCubicCapacityRef}
                                        label="Objem válců (ccm)"
                                        onChange={(val) => onChange('vehicleCubicCapacity', val)}
                                        value={product.vehicleCubicCapacity}
                                        required={requiredFields.includes("vehicleCubicCapacity")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehiclePowerRef}
                                        label="Výkon (kw)"
                                        onChange={(val) => onChange('vehiclePower', val)}
                                        value={product.vehiclePower}
                                        required={requiredFields.includes("vehiclePower")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleDriveRef}
                                        defaultValue="- nevybráno -"
                                        label="Pohon"
                                        onChange={(val) => onChange('vehicleDrive', val)}
                                        value={product.vehicleDrive}
                                        options={listsStore.getOptionsBy(ListType.VehicleDrive)}
                                        required={requiredFields.includes("vehicleDrive")}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <label className="section-title pb-2">Karoserie</label>
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehicleDoorCountRef}
                                        label="Počet dveří"
                                        onChange={(val) => onChange('vehicleDoorCount', val)}
                                        value={product.vehicleDoorCount}
                                        maxValue={100}
                                        required={requiredFields.includes("vehicleDoorCount")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehicleNumberOfSeatsRef}
                                        label="Počet sedadel"
                                        onChange={(val) => onChange('vehicleNumberOfSeats', val)}
                                        value={product.vehicleNumberOfSeats}
                                        maxValue={100}
                                        required={requiredFields.includes("vehicleNumberOfSeats")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleColorRef}
                                        defaultValue="- nevybráno -"
                                        label="Barva"
                                        onChange={(val) => onChange('vehicleColor', val)}
                                        value={product.vehicleColor}
                                        options={Product.getColorOptions()}
                                        required={requiredFields.includes("vehicleColor")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehicleWeightRef}
                                        label="Hmotnost (kg)"
                                        onChange={(val) => onChange('vehicleWeight', val)}
                                        value={product.vehicleWeight}
                                        required={requiredFields.includes("vehicleWeight")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <label className="section-title pb-2">Doplňující údaje</label>
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <InputNumber
                                        ref={vehicleNumberOfVehicleOwnersRef}
                                        label="Počet majitelů"
                                        onChange={(val) => onChange('vehicleNumberOfVehicleOwners', val)}
                                        value={product.vehicleNumberOfVehicleOwners}
                                        maxValue={10}
                                        required={requiredFields.includes("vehicleNumberOfVehicleOwners")}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-3 col-lg-6">
                                    <SelectBox
                                        ref={vehicleCountryOriginRef}
                                        defaultValue="- nevybráno -"
                                        label="Země původu"
                                        onChange={(val) => onChange('vehicleCountryOrigin', val)}
                                        value={product.vehicleCountryOrigin}
                                        options={CountryHelper.getCountryOptions()}
                                        required={requiredFields.includes("vehicleCountryOrigin")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
})))
import { observable, makeAutoObservable, action } from 'mobx'
import { IProductFilter } from '../../Interfaces/IProductFilter';
import IUser from '../../Interfaces/IUser';
import ErrorReporter from '../Utils/ErrorReporter';


export default class CurrentUserStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable user: IUser;

    @action
    setCurrentUser(user: IUser) {
        if (user) {
            this.user = user;
            ErrorReporter.setUser(user);
        }
    }

    @observable searchFilter: IProductFilter =
        {
            search: '',
            onlineServer: null,
            onlineServerState: null,
            productCollectionId: null,
            enabled: null,
            reserved: false,
        }

    @action setSearchFilter(filter: IProductFilter) {
        this.searchFilter = filter;
    }
}
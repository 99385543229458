import React, { useEffect, useImperativeHandle, useRef } from "react";
import IProduct from "../../../Interfaces/IProduct";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import KleinanzeigenApiService from "../../../Services/KleinanzeigenApiService";
import { IField } from "../../../Interfaces/IField";
import { Translate } from "react-bootstrap-icons";

interface IProps {
    product: IProduct,
    onChange(value: any, key: string): void,
    onBatchUpdate(values: { [key: string]: any }): void,
    validChanged?(isValid: boolean): void,
}

const kleinanzeigenApiService = new KleinanzeigenApiService();

export const KleinanzeigenEditor = React.forwardRef<IValidationFormRef, IProps>(({ product, onChange, validChanged, onBatchUpdate }, ref) => {
    const inputName = useRef<InputText>(null);
    const inputDescription = useRef<Textarea>(null);


    useEffect(() => {
        return () => {
            kleinanzeigenApiService.cancelRequests();
        }
    }, [])

    const getInputs = () => {
        return [inputName, inputDescription];
    };

    const validate = (): boolean => {
        const inputs = getInputs();
        const isValid = inputs.every(input => input.current?.validate());
        if (validChanged) {
            validChanged(isValid);
        }
        return isValid;
    };

    useImperativeHandle(ref, () => ({
        getInputs,
        validate
    }));

    const generateContent = () => {
        kleinanzeigenApiService.translate(product)
            .then(data => {
                const fields = data.others.get("fields") as IField;

                // Create a temporary object to hold all changes
                const updatedFields: { [key: string]: any } = {};

                Object.entries(fields).forEach(([key, value]) => {
                    updatedFields[key] = value;
                });
                // Apply all changes at once
                onBatchUpdate(updatedFields);
            });
    };

    return (
        <>
            <div className="pb-3 pt-1">
                <CheckBox
                    checked={product.kleinanzeigenCustomContent}
                    onChange={(value) => onChange(value, 'kleinanzeigenCustomContent')}
                    label="Nastavit jiný obsah"
                />
            </div>

            <div className={product.kleinanzeigenCustomContent ? 'd-block' : 'd-none'}>
                <button
                    className="btn btn-ai float-right"
                    onClick={generateContent}
                >
                    <Translate /> Generovat překlady
                </button>

                <InputText
                    ref={inputName}
                    label="Nadpis"
                    onChange={(value) => onChange(value, 'kleinanzeigenName')}
                    value={product.kleinanzeigenName || ''}
                    required
                    forbidSpecialChars={true}
                    maxLength={60}
                />

                <Textarea
                    ref={inputDescription}
                    label="Popis"
                    onChange={(value) => onChange(value, 'kleinanzeigenDescription')}
                    required
                    value={product.kleinanzeigenDescription || ''}
                    rows={13}
                    maxLength={3000}
                />
            </div>
        </>
    );
});
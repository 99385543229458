import { useEffect, useRef } from 'react';
import CurrencyHelper from '../../Helpers/CurrencyHelper';
import LanguageHelper from '../../Helpers/LanguageHelper';
import WebsiteHelper from '../../Helpers/WebsiteHelper';
import { IWebsite } from '../../Interfaces/IWebsite';
import InputEmail from '../UI/Input/InputEmail';
import InputTelephone from '../UI/Input/InputTelephone';
import InputText from '../UI/Input/InputText';
import InputUrl from '../UI/Input/InputUrl';
import SelectBox from '../UI/Input/SelectBox';
import WebsitesApiService from '../../Services/WebsitesApiService';
import { SectionTitle } from '../Others/SectionTitle';
import { WebColorPicker } from '../UI/Input/WebColorPicker';
import IInputField from '../../Interfaces/IInputField';
import CheckBox from '../UI/Input/CheckBox';

interface IProps {
    website: IWebsite,
    handleChange(key: string, value: any): void,
    handleChangeSettings(key: string, value: any): void,
}

const websitesApiService = new WebsitesApiService();

export const WebsiteGeneralSettings = (props: IProps) => {

    const { website, handleChange, handleChangeSettings } = props;

    useEffect(() => {
        return () => {
            websitesApiService.cancelRequests();
        }
    }, [])

    const inputName = useRef(null);
    const inputPhone = useRef(null);
    const inputEmail = useRef(null);
    const inputAnotherContact = useRef(null);
    const inputType = useRef(null);
    const inputCurrency = useRef(null);
    const inputLanguage = useRef(null);
    const inputFacebookLink = useRef(null);
    const inputInstagramLink = useRef(null);
    const inputYoutubeLink = useRef(null);
    const inputTwitterLink = useRef(null);
    const primaryColorRef = useRef<IInputField>();
    const secondaryColorRef = useRef<IInputField>();

    const getInputs = () => [
        inputName,
        inputType,
        inputLanguage,
        inputCurrency,
        inputFacebookLink,
        inputInstagramLink,
        inputYoutubeLink,
        inputTwitterLink,
        primaryColorRef,
        secondaryColorRef,
    ];

    return (
        <div className="mt-3 p-3">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="alert alert-warning">
                        <CheckBox
                            label="Režim údržby"
                            checked={website.maintenance}
                            onChange={(val) => handleChange('maintenance', val)}
                        />
                        <small>Režim údržby vypněte, jakmile budete mít web kompletně nastavený. Můžete jej také kdykoli zapnout.</small>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4">
                    <InputText
                        ref={inputName}
                        label="Název webu"
                        value={website.name}
                        required={true}
                        onChange={(val) => handleChange('name', val)}
                        placeholder="Veřejný název webu"
                    />

                    <SelectBox
                        ref={inputLanguage}
                        label="Jazyk"
                        value={website.language}
                        defaultValue="Vyberte"
                        required
                        options={LanguageHelper.getOptions()}
                        onChange={(value: string) => handleChange('language', value)}
                    />
                </div>
                <div className="col-lg-4">
                    <SelectBox
                        ref={inputType}
                        label="Typ webu"
                        value={website.type}
                        defaultValue="Vyberte"
                        required
                        options={WebsiteHelper.getTypeOptions()}
                        onChange={(value: string) => handleChange('type', value)}
                    />

                    <SelectBox
                        ref={inputCurrency}
                        label="Měna"
                        value={website.currency}
                        defaultValue="Vyberte"
                        required
                        options={CurrencyHelper.getOptions()}
                        onChange={(value: string) => handleChange('currency', value)}
                    />
                </div>
            </div>

            {/* <CheckBox
                        label="Tlačítko " formulář na produktové stránce"
                        checked={website.productShowContactForm}
                        onChange={(val) => handleChange('productShowContactForm', val)}
                    />*/}

            <CheckBox
                label="Zobrazit tlačítko objednat s formulářem na produktové stránce"
                checked={website.productShowOrderForm}
                onChange={(val) => handleChange('productShowOrderForm', val)}
            />

            <hr />

            <SectionTitle title="Kontaktní údaje" />

            <p>Tyto údaje budou zobrazeny u jednotlivých produktů a v rámci webu.</p>

            <div className="row mt-3">
                <div className="col-lg-4">
                    <InputTelephone
                        ref={inputPhone}
                        label="Tel. číslo"
                        onChange={(val) => handleChangeSettings('phoneNumber', val)}
                        value={website.settings.phoneNumber}
                    />
                </div>
                <div className="col-lg-4">
                    <InputEmail
                        ref={inputEmail}
                        label="Email"
                        onChange={(val) => handleChangeSettings('email', val)}
                        value={website.settings.email}
                    />
                </div>
            </div>

            <hr />

            <SectionTitle title="Sociální sítě" />
            <p>Přidejte na web odkazy na vaše sociální sítě.</p>

            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <InputUrl
                        ref={inputFacebookLink}
                        label="Facebook"
                        onChange={(val) => handleChangeSettings('facebookLink', val)}
                        value={website.settings.facebookLink}
                        required={false}
                    />
                </div>

                <div className="col-md-6 col-lg-3">
                    <InputUrl
                        ref={inputInstagramLink}
                        label="Instagram"
                        onChange={(val) => handleChangeSettings('instagramLink', val)}
                        value={website.settings.instagramLink}
                        required={false}
                    />
                </div>

                <div className="col-md-6 col-lg-3">
                    <InputUrl
                        ref={inputYoutubeLink}
                        label="Youtube"
                        onChange={(val) => handleChangeSettings('youtubeLink', val)}
                        value={website.settings.youtubeLink}
                        required={false}
                    />
                </div>

                <div className="col-md-6 col-lg-3">
                    <InputUrl
                        ref={inputTwitterLink}
                        label="X (Twitter)"
                        onChange={(val) => handleChangeSettings('twitterLink', val)}
                        value={website.settings.twitterLink}
                        required={false}
                    />
                </div>
            </div>

            <hr />

            <SectionTitle title="Vzhled" />
            <p>Přizpůsobte si barvu webu dle vaší firemní identity.</p>

            <div className="row">
                <div className="col-md-6 col-lg-6">
                    <WebColorPicker
                        ref={primaryColorRef}
                        label="Primární barva"
                        value={website.settings.primaryColor}
                        onChange={(val) => handleChangeSettings('primaryColor', val)}
                    />
                </div>
                <div className="col-md-6 col-lg-6">
                    <WebColorPicker
                        ref={secondaryColorRef}
                        label="Sekundární barva"
                        value={website.settings.secondaryColor}
                        onChange={(val) => handleChangeSettings('secondaryColor', val)}
                    />
                </div>
            </div>
        </div >
    )
}
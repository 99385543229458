import React from 'react';
import { observer, inject } from 'mobx-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import IUser from '../../../Interfaces/IUser';
import BaseComponent from '../../BaseComponent';
import InputPassword from '../../UI/Input/InputPassword';
import IInputField from '../../../Interfaces/IInputField';
import LoadingScreen from '../../Screens/LoadingScreen';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import LoadingErrorScreen from '../../Screens/LoadingErrorScreen';
import InputEmail from '../../UI/Input/InputEmail';
import InputText from '../../UI/Input/InputText';
import CurrentUserStore from '../../Stores/CurrentUserStore';

interface IState {
    loading: boolean,
    loadingError: boolean,
    user: IUser,
    changePassword: {
        currentPassword: string,
        newPassword1: string,
        newPassword2: string
    },
}

interface IProps {
    currentUserStore?: CurrentUserStore,
}

@inject('currentUserStore')
@observer
export default class SettingsPage extends BaseComponent<IProps, IState> {

    private inputCurrentPassword: React.RefObject<InputPassword>;
    private inputNewPassword1: React.RefObject<InputPassword>;
    private inputNewPassword2: React.RefObject<InputPassword>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            loadingError: false,
            user: {
                id: '',
                email: '',
                dateExpirationUploader: null,
                name: '',
                activeUploader: null,
                facebookAccessToken: '',
                facebookConnected: false,
            },
            changePassword: {
                currentPassword: '',
                newPassword1: '',
                newPassword2: '',
            },
        }

        this.inputCurrentPassword = React.createRef();
        this.inputNewPassword1 = React.createRef();
        this.inputNewPassword2 = React.createRef();
    }

    componentDidMount() {
        this.userApiService.getCurrentUser()
            .catch(response => {
                this.setState({
                    loadingError: true
                })
            }).finally(() => {
                this.setState({
                    loading: false
                })

                this.setState({
                    user: this.props.currentUserStore.user
                })
            })
    }

    handleChange(key: string, value: any) {
        this.setState({
            user: {
                ...this.state.user,
                [key]: value
            }
        })
    }

    handleChangePassword(key: string, value: any) {
        this.setState({
            changePassword: {
                ...this.state.changePassword,
                [key]: value
            }
        })
    }

    handleSubmitChangePassword() {
        if (this.isValidChangePasswordForm()) {
            axios.post('user/change-password', this.state.changePassword)
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            changePassword: {
                                currentPassword: '',
                                newPassword1: '',
                                newPassword2: ''
                            }
                        })
                    }
                });
        }
    }

    validateNewPasswords(pass: any, errors: Array<string>, validateInput: Function) {
        if (this.state.changePassword.newPassword1 != pass && pass.length >= 8) {
            errors.push('Nové hesla jsou odlišné');
        }
        validateInput(pass, errors);
    }

    isValidChangePasswordForm() {
        const inputs: React.RefObject<IInputField>[] = [];
        inputs.push(
            this.inputCurrentPassword,
            this.inputNewPassword1,
            this.inputNewPassword2
        )


        let firstInvalid: IInputField;
        for (var i = 0; i < inputs.length; i++) {
            const input: IInputField = inputs[i].current;
            input.validate();
            if (!input.isValid() && !firstInvalid) {
                firstInvalid = input;
                input.focus();
            }
        }
        if (firstInvalid) {
            return false;
        }
        return true;
    }

    render() {
        let user = this.state.user;

        if (this.state.loading) {
            return <LoadingScreen />
        }
        if (this.state.loadingError) {
            return <LoadingErrorScreen />
        }

        return (
            <CustomDocumentTitle title="Nastavení">
                <div className="mt-2">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="page-title">Změna hesla</h4>
                            <div className="card">
                                <div className="card-body">
                                    <InputPassword
                                        ref={this.inputCurrentPassword}
                                        minLength={8}
                                        maxLength={16}
                                        label="Aktuální heslo"
                                        onChange={this.handleChangePassword.bind(this, 'currentPassword')}
                                        value={this.state.changePassword.currentPassword}
                                    />

                                    <InputPassword
                                        ref={this.inputNewPassword1}
                                        minLength={8}
                                        maxLength={16}
                                        label="Nové heslo"
                                        onChange={this.handleChangePassword.bind(this, 'newPassword1')}
                                        value={this.state.changePassword.newPassword1}
                                    />

                                    <InputPassword
                                        ref={this.inputNewPassword2}
                                        minLength={8}
                                        maxLength={16}
                                        label="Nové heslo (kontrola)"
                                        onChange={this.handleChangePassword.bind(this, 'newPassword2')}
                                        value={this.state.changePassword.newPassword2}
                                        validate={this.validateNewPasswords.bind(this)}
                                    />

                                    <button onClick={this.handleSubmitChangePassword.bind(this)} className="btn btn-primary">
                                        Změnit heslo
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <h4 className="page-title">{user.name}</h4>
                            <div className="card mb-2" >
                                <div className="card-body">
                                    <InputEmail
                                        value={user.email}
                                        readonly={true}
                                        required={false}
                                        label="Email"
                                    />

                                    <InputText
                                        value={`${user.productsCount} inzerátů (max. ${user.productLimitMax === -1 ? 'neomezeně' : user.productLimitMax})`}
                                        label={`Využití`}
                                        readonly={true}
                                    />
                                </div>
                            </div>

                            <div className="card mb-2">
                                <Link className="dropdown-item clickable" to='/ips'> <i className="fas fa-network-wired mr-2" />IP adresy</Link>
                                <Link className="dropdown-item clickable" to='/cenik'> <i className="fas fa-money-bill mr-2" />Ceník</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDocumentTitle>
        )
    }
}
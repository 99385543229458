import * as React from 'react';
import IInputField from '../../../Interfaces/IInputField';
import InputText from './InputText';

export default class InputBankAccount extends InputText implements IInputField {

    handleChange(e: any) {
        let match: RegExpMatchArray = (e.target.value as string).match(/^([0-9\-]*)$/);
        let value: string;
        if (match !== null) {
            value = match[0];
        } else {
            value = this.props.value;
        }
        if (value !== this.props.value) {
            this.props.onChange(value);
            this.validate(value);
        } 
    }

    validateInput(value: string, errors: string[]) {
        let match: RegExpMatchArray = value.match(/^([0-9]+)(-[0-9]+)?$/);
        if (this.props.required === false && this.props.value.length === 0) {
            return;
        }

        if (!match) {
            errors.push("Formát bankovního účtu není správný");
        }
        super.validateInput(value, errors);
    }

    renderError() {
        if (this.state.errors.length == 0) { return; }

        return (
            <ul className="parsley-errors-list filled" id="parsley-id-5">
                <li className="parsley-required">{this.state.errors[0]}</li>
            </ul>
        )
    }

    renderInput() {
        return (
            <input
                ref={this.input}
                type="text"
                className={`form-control ${this.state.errors.length === 0 ? '' : 'parsley-error'}`}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.handleChange.bind(this)}
            />
        );
    }

    renderLabel() {
        return (
            <label className="form-control-label">
                {this.props.label} {this.props.required ? <span className="tx-danger">*</span> : null}
            </label>
        );
    }

    render() {
        return (
            <div className="form-group">
                {this.renderLabel()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
}
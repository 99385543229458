
export enum CustomContentType {
    BazosCz,
    BazosSk,
    Sbazar,
    Tipmoto,
    FacebookShop,
    FacebookMarketplace,
    Website,
    SalesCard,
    Kleinanzeigen,
}
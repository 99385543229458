import { load } from "cheerio";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ProductApiService from "../../Services/ProductApiService";
import Textarea from "../UI/Input/Textarea";
import { ValidationHelper } from "../Utils/ValidationHelper";

interface IProps {
    title: string,
    onHide(): void,
    onGenerated(description: string): void,
}

const productApiService = new ProductApiService();

export const GenerateDescriptionAiModal = (props: IProps) => {

    const { title } = props;
    const [loading, setLoading] = useState(false);

    const [shortInfo, setShortInfo] = useState("");
    const shortInfoRef = useRef<Textarea>(null);

    useEffect(() => {

        return (() => {
            productApiService.cancelRequests();
        })
    }, [])

    const onSubmit = () => {
        const inputs = [shortInfoRef];
        const isValid = ValidationHelper.validateInputs(inputs);

        if (isValid) {
            setLoading(true);

           /* productApiService.generateDescription(title, shortInfo)
                .then(data => {
                    setLoading(false);

                    if (data.success) {
                        const description = data.others.get("description");
                        props.onGenerated(description);
                    }
                })*/
        }
    }

    const onHide = () => {
        if (!loading) {
            props.onHide();
        }
        else {
            toast.warn("Počkejte na dokončení generování");
        }
    }

    return (
        <Modal centered={true} show={true} onHide={onHide}>
            <Modal.Header closeButton className="bg-secondary">
                <Modal.Title style={{ fontSize: 20 }}>Generování popisu pomocí umělé inteligence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading &&
                    <>

                    <p>
                        <span className="text-primary"><b>Umělá inteligence vám napíše popisek</b></span>. Po vygenerování si popisek můžete upravit.
                    </p>
         
                        <Textarea
                            ref={shortInfoRef}
                            required
                            label="Zadejte základní údaje o produktu, stačí heslovitě"
                            value={shortInfo}
                            onChange={(val) => setShortInfo(val)}
                            placeholder=""
                        />

                        <p>např. plně funkční, včetně nabíječky a pouzdra, za 100 Kč můžu přidat i SD kartu. Osobní převzetí Praha</p>  
                    </>}

                {loading && <div className="my-5 text-center">
                    <h5 className="text-primary">Probíhá generování</h5>
                    <p><b>Může trvat i minutu. Vyčkejte prosím na této stránce.</b></p>
                </div>}

            </Modal.Body>
            <Modal.Footer>
                {!loading && <>< Button onClick={onSubmit} variant="primary">
                    Generovat popis
                </Button>
                    <Button onClick={onHide} variant="secondary">
                        Zavřít
                    </Button>
                </>}
            </Modal.Footer>
        </Modal>)

}
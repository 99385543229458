import { Link } from 'react-router-dom';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { PageTitleBox } from '../../Others/PageTitleBox';

export const PricingPage = () => {
    return (
        <CustomDocumentTitle title="Ceník">
            <PageTitleBox title="Ceník" />

            <div className="card">
                <div className="card-body">
                    <label className="section-title mt-0 mb-3">Správa inzerce</label>

                    <hr />

                    <p>Spravujte své inzeráty z jednotné administrace či zcela automatizujte díky naším integracím.</p>

                    <div className="row">
                        <div className="col">
                            <Link
                                to='/cenik/automoto'
                                className="btn btn-outline-primary btn-lg btn-block"
                            >
                                Autobazar / Motobazar / Pracovní technika
                            </Link>
                        </div>

                        <div className="col">
                            <Link
                                to='/cenik/standard'
                                className="btn btn-outline-primary btn-lg btn-block"
                            >
                                Ostatní prodejci
                            </Link>
                        </div>
                    </div>

                    <label className="section-title mt-4 mb-3">
                        Webové služby
                    </label>

                    <hr />

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Link to='/cenik/weby' className="btn btn-outline-primary btn-lg btn-block">
                                Prodejní web na vlastní doméně
                            </Link>
                        </div>
                    </div>

                    <label className="section-title mt-4 mb-3">Ostatní</label>

                    <hr />

                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <b>1 IP navíc</b>
                                            </td>

                                            <td></td>

                                            <td>
                                                40 Kč / 1.63 € / měsíc
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <b>Funkce umělé inteligence</b>
                                            </td>

                                            <td></td>

                                            <td>
                                                Dle vypočetního výkonu - strhávají se z kreditu
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <b>Ostatní</b>
                                            </td>

                                            <td>Máte zájem o cokoli jiného? Kontaktujte <Link to="/kontakt">podporu</Link></td>

                                            <td>
                                                1 000 Kč / 40 € / hodina
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>Ceny jsou konečné. Nejsme plátci DPH.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomDocumentTitle>
    )
}
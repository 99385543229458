import { orderBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import { PageTitleBox } from '../../Others/PageTitleBox';
import LoadingScreen from '../../Screens/LoadingScreen';
import AccountTransactionStore from '../../Stores/AccountTransactionStore';
import CurrentUserStore from '../../Stores/CurrentUserStore';
import { TransactionsTable } from '../../Transactions/TransactionsTable';
import CustomDocumentTitle from '../../Utils/CustomDocumentTitle';
import { useEffect, useState } from 'react';
import AccountTransactionsApiService from '../../../Services/AccounTransactionsApiService';
import Pagination from 'react-js-pagination';

interface IProps {
    accountTransactionStore?: AccountTransactionStore,
    currentUserStore?: CurrentUserStore,
}

const accountTransactionsApiService = new AccountTransactionsApiService();

export const AccountTransactionsPage =
    inject('accountTransactionStore', 'currentUserStore')
        (observer((props: IProps) => {

            const countToShow = 50;
            const [loading, setLoading] = useState<boolean>(true);
            const [transactionIds, setTransactionIds] = useState<string[]>([]);
            const [totalCount, setTotalCount] = useState<number>(0);
            const [currentPage, setCurrentPage] = useState<number>(1);

            useEffect(() => {
                window.scrollTo(0, 0);

                onChangePage(1);
                return () => {
                    accountTransactionsApiService.cancelRequests();
                }
            }, []);

            const transactions = orderBy(props.accountTransactionStore.getByIds(transactionIds), ['date'], ['desc']);

            const onChangePage = (pageNumber: number) => {

                setLoading(true);


                let skipCount = 0;

                if (pageNumber >= 2) {
                    skipCount = (pageNumber - 1) * countToShow;
                }


                accountTransactionsApiService.getAccountTransactions(countToShow, skipCount)
                    .then(data => {
                        setLoading(false);
                        setTransactionIds(data.records.accountTransactions.items.map(s => s.id));
                        setCurrentPage(pageNumber);

                        const totalItems = data.others.get("totalCount")

                        setTotalCount(totalItems);
                    })

            };

            const renderPagination = () => {
                if ((totalCount) > countToShow) {
                    return (
                        <Pagination
                            pageRangeDisplayed={countToShow}
                            activePage={currentPage}
                            itemsCountPerPage={countToShow}
                            totalItemsCount={totalCount}
                            onChange={n => onChangePage(n)}
                            linkClass="page-link"
                            itemClass="page-item"
                            innerClass="pagination justify-content-center mt-3"
                            hideDisabled={true}
                            hideFirstLastPages={true}
                        />
                    );
                }
            }

            return (
                <CustomDocumentTitle title="Transakce">
                    <PageTitleBox title="Transakce" />

                    {loading ? <LoadingScreen /> : <div className="card">
                        <div className="card-body">
                            <TransactionsTable
                                transactions={transactions}
                            />
                        </div>
                        {renderPagination()}
                    </div>}
                </CustomDocumentTitle>
            )
        }))